import $ from 'jquery';
import Typed from 'typed.js';

// Typed animation
const div = document.querySelector('body');
if (div.classList.contains('home')) {
  $(document).ready(function () {
    const strings = $('.home .header .container .header-title .element').attr('data-strings');
    // eslint-disable-next-line
    var typed = new Typed('.home .header .container .header-title .home_title .element', {
      strings: strings.split(','),
      backSpeed: 60,
      typeSpeed: 120,
      loop: true,
      showCursor: true,
      backDelay: 2000
    });
  });
}
